<template>
  <div>
    <v-select
      dense
      outlined
      :label="$t('order.data.status.after_reject_return')"
      :items="orderStatus"
      v-model="data.status"
    ></v-select>
  </div>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    popupConfig: Object,
  },
  data: (vm) => ({
    data: {
      status: vm.$orderConstants.STATUS_DELIVERED,
    },
  }),
  computed: {
    orderStatus() {
      const list = [
        this.$orderConstants.STATUS_DELIVERED,
        this.$orderConstants.STATUS_FINISHED,
      ]

      return list.map(status => ({
        text: this.$t(`order.data.status.${status}`),
        value: status,
      }))
    },
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('updateData', window.eagleLodash.cloneDeep(this.data))
      },
    }
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
